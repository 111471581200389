import React, {useState , useEffect } from 'react';
import axios from 'axios';
import ArticlePreview from '../ArticlePreview.js';
import {analytics} from '../../Firebase.js';


const logAnalyticsCurrentPage = () => {
	analytics.setCurrentScreen(window.location.pathname); // sets `screen_name` parameter
	analytics.logEvent('screen_view'); // log event with `screen_name` parameter attached
};


function Blog (props){
	logAnalyticsCurrentPage();

	
	const[posts, setPosts] = useState([]);

	//console.log("Props in BlogJs " + JSON.stringify(props));
	// console.log("Reading blog " + props.location.state.onBlog);
	
	//Use effect stops infinite re-render when setPost is called
	useEffect(() => {
		axios
		.get("https://public-api.wordpress.com/rest/v1.1/sites/habitprompt.wordpress.com/posts/", {transformRequest: (data, headers) => {
    delete headers.common['Authorization']}})
		.then(res => {
			//console.log("*** DATA ***: " + JSON.stringify(res.data.posts));
			 setPosts(res.data.posts);

		})
		.catch(error => console.log(error));
	}, []);

	return(
		<React.Fragment>
	 <div className="blog-article-preview">
        {posts.map((post, key) => <ArticlePreview key={key} post={post} />)}
      </div>
		</React.Fragment>
	);

}

export default Blog;