import React, {useState , useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import "./BlogArticle.css";


function BlogArticle (props){

// const [blogData, setBlogData] = useState([]);
const [blogData, setBlogData] = useState('');

// console.log("Props for Blog article component " + JSON.stringify(props));
// console.log("Props params " + props.history.location.pathname)
// console.log("blog id is  " + props.match.params.id);


  useEffect(() => {
    axios
    // .get("https://public-api.wordpress.com/rest/v1.1/sites/habitprompt.wordpress.com/posts/" + props.match.params.id)
    // .get("https://public-api.wordpress.com/rest/v1.1/sites/habitprompt.wordpress.com/posts/" + props.location.blogid)
    // .get("https://public-api.wordpress.com/rest/v1.1/sites/habitprompt.wordpress.com/posts?slug=" + props.location.blogid)
    .get("https://public-api.wordpress.com/rest/v1.1/sites/habitprompt.wordpress.com/posts/slug:"+props.match.params.id , {transformRequest: (data, headers) => {
    delete headers.common['Authorization'];
      }})
    .then(res => {
      // console.log("Blog axios being called ");
      // console.log("Response content " + JSON.stringify(res.data));
      console.log("Response data content " + res.data.content);
      // res.data.content = res.data.content.replace(/(?:\r\n|\r|\n)/g, '<br />');
      res.data.content = res.data.content.replace(/[\r\n]+/g,'<br />');
      // setBlogData(res.data.posts[0]);
      setBlogData(res.data);
      // console.log("Content id " + res.data.ID);
      // console.log("Content id " + JSON.stringify(res.data.posts[0].ID));
      // console.log("Content id " + JSON.stringify(res.data.posts[0].content));

  
    })
    .catch(error => console.log(error));
  }, []);


  function createMarkup(argument) {
    return {__html: argument};
  }

  return (
    <div className="blog-article-container">
        <h1 className="text-center" dangerouslySetInnerHTML={createMarkup(blogData.title)}></h1>
        {/*<div className="content" dangerouslySetInnerHTML={createMarkup(blogData.content)}></div>*/}
        <div className="main-article-content" dangerouslySetInnerHTML={createMarkup(blogData.content)}></div>
        <div><a href>Log in</a> or <a href> Sign up</a> to leave a comment and be part of the habitprompt family</div>
          <Link to={"/blog"}>
            <button className="blog-article-btn">Back to blog</button>
          </Link>
    </div>
  );



}

export default BlogArticle;