import React from 'react';




function CardItem(props) {
  
  const style = {
    width: props.percent
  };

  return (
    <React.Fragment>
      <li className='cards__item'>
        <div className='cards__item__link'>
          <figure className='cards__item__pic-wrap'>
            <img
              className='cards__item__img'
              alt={props.text}
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <div className='cards__item__text'>{props.text}</div>
            <div className='card__item__percent'>{props.percent}</div>
            <div className='meter'>
              <span className='progress_bar' style={style}></span>
            </div>
          </div>
        </div>
      </li>
    </React.Fragment>
  );
}

export default CardItem;