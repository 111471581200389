import React, { lazy , Suspense } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection.js';


const CardSection = lazy(() => import('../Cards.js'));
const FooterSection = lazy(() => import('../Footer.js'));

const renderLoader = () => <p>Loading</p>

function Home () {
	return(
		<React.Fragment>
			<HeroSection />
			<Suspense fallback={renderLoader()}>
				<CardSection />
				<FooterSection />
			</Suspense>
		</React.Fragment>
	);
}

export default Home;