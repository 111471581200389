import React, {useState} from 'react';
import { Link } from 'react-router-dom';
// import { Redirect } from 'react-router';
import './ArticlePreview.css';


function ArticlePreview (props){


function createMarkup(argument) {
  return {__html: argument};
}




if (props.post) {

	const articleRoute = {
		pathname: "/blog/" + props.post.slug,
		// blogid: props.post.ID,
	};

      return (
        <div className="ArticlePreviewContianer">
          <a href={"/blog/" + props.post.slug} className="blackLink">
            {props.post.featured_image ? (
              <img
                className="img-responsive"
                alt="article header"
                src={props.post.featured_image}
              />
            ) : (
              ""
            )}
            <h1 className="text-center" dangerouslySetInnerHTML={createMarkup(props.post.title)}></h1>
            <div className="content" dangerouslySetInnerHTML={createMarkup(props.post.excerpt)}></div>
          </a>
         {/*<Link to={"/blog/" + props.post.ID}> */}
         <Link to={articleRoute} href={props.post.slug}> 
            <button className="ArticlePreview-btn">Full Post</button>
          </Link>
        </div>
      );

    } else {
      return null;
    }


}

export default ArticlePreview;