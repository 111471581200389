import './App.css';
import Navbar from './components/Navbar.js';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './components/pages/Home.js';
import FeedbackForm from './components/pages/FeedbackForm.js';
import MyProfile from './components/pages/MyProfile.js';
import Blog from './components/pages/Blog.js';
import BlogArticle from './components/pages/BlogArticle.js';
import React, { component, useState }  from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import NavContext from './components/NavbarGlogbalContext';



// const stripePromise = loadStripe('pk_test_51JnEdaJpoVqOpoDDMgbfYSAZTLxEI6nBs22tdmIsmqI7E3HqNpyYvpzayPo8sfkEgG4wcNxhW4p0dmRYtVOJdW2S00ys9dflxU');
const stripePromise = loadStripe('pk_live_51JnEdaJpoVqOpoDD0gihFgcQJ5zmR1jOFIABni2ADDO3zk8yvnS4fgaSnaaGoVDLvQPeMhiYmNE3b15SyCd3gGC300vvvnB3Mu');


function App() {

  const [testSetLoggedIn, setTestSetLoggedIn] = useState(false);

  return (
     <Router>
        <Elements stripe={stripePromise}>
          
          <NavContext.Provider value={{testSetLoggedIn, setTestSetLoggedIn}}>
             {console.log("We're in app js")}
              {console.log("Constext provider: " + testSetLoggedIn)}
           
            <Navbar/>
            <Switch>
              <Route path='/blog/:id' exact component={BlogArticle}/>  
              <Route path='/blog' exact component={Blog}/>  
              <Route path='/feedback' exact component={FeedbackForm}/>
              <Route path='/myprofile' exact component={MyProfile}/>  
              <Route path='/' exact component={Home}/>
            </Switch>  

          </NavContext.Provider>  

        </Elements>
     </Router> 

  );
}

export default App;
