import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { db, analytics } from '../../Firebase.js'
import '../FeedbackForm.css';



const logAnalyticsCurrentPage = () => {
	analytics.setCurrentScreen(window.location.pathname); // sets `screen_name` parameter
	analytics.logEvent('screen_view'); // log event with `screen_name` parameter attached
};

function FeedbackForm () {
	logAnalyticsCurrentPage(); 

	const { handleSubmit, formState, errors, register} = useForm();
	
	const navContainerStyle = {
		justifyContent: "left",
		paddingLeft: "60px"
	}

	//const { state: {userId} } = useLocation(); 
	//console.log("User id in FeedbackForm is: "+ userId);

	const [textareaData, setTextAreaData] = useState('');
	const [beingSubmitted, setBeingSubmitted] = useState(false);
	const [notSubmitted, setNotSubmitted] = useState(true);
	const [submitted, setSubmitted] = useState(false);


	const handleChange = (event) => {
		setTextAreaData(event.target.value);	
	}
	
	const collectMessageData = () => {

		setBeingSubmitted(true);
		setNotSubmitted(false);

		//console.log('submitting message: ' + textareaData);
		
		// db.collection('contacts').doc(userId).update({
		//     feedback: textareaData
		// })
		// .then(function() {
		// 	setBeingSubmitted(false);
		// 	setSubmitted(true);
		// })
		// .catch(function(error) {
		//     console.error("Error updating document: ", error);
		//     setBeingSubmitted(false);
		// });
	

	}

	return(
		<React.Fragment>
{/*			<nav className='navbar' style={navContainerStyle}>
				<div className='navbar-container'>
					<Link to='/' className='navbar-logo'>
			            <img alt='habitprompt' src='./Logo.svg' className='logo-icon' />
			            <div className='logo-text'>habitprompt</div>
			        </Link>
				</div>
			</nav>
*/}
			<div className='container'>
				<div className='generic site-under-construction'>For any support questions</div>	
				<div className='generic'>or if you would like to cancel your subscription and close your account</div>
				<div className='generic'>Please email us at <a href = "mailto: habitprompt@gmail.com" style={{color:'#3EE2CA'}}>habitprompt@gmail.com</a></div>
				<div className='generic'> - The Habitprompt team.</div>
				{/*<div className='generic'>Feedback box: </div>*/}
{/*				<div className='text-container'>
					<div className='form-area'>
						<form className='form-style' onSubmit={handleSubmit(collectMessageData)}>
							<textarea 
								className='textarea' 
								id='feedbackmessage' 
								name='feedbackmessage' 
								value={textareaData} 
								onChange={handleChange}
								ref={register({ required: "Feedback form cannot be empty" })} 
								style={{borderColor: errors.feedbackmessage && "red"}}
							/>
							{errors.feedbackmessage && <div className='error-message'>{errors.feedbackmessage.message}</div>}
							<div>
								<button
								className='submit-button'
								style={{backgroundColor: submitted ? "#54be59" : '#FD4974' }}
								disabled={formState.isSubmitting || submitted}
						    	>
								    {notSubmitted && <span>Submit</span>}
								    {beingSubmitted && <i className="fa fa-spinner fa-spin"></i>}
								    {submitted && <span>Done</span>}
							  	</button>
							</div>
						</form>
					</div>
				</div>*/}
			</div>		
		</React.Fragment>
	);
}

export default FeedbackForm;