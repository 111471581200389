import React, { useState, useEffect, Suspense} from 'react';
import { authMiddleWare } from '../../util/auth';
import axios from 'axios';
import './MyProfile.css';
import CardItem from '../CardItem.js';
import '../Cards.css';
import {analytics, firebaseAuth, messaging } from '../../Firebase.js';
import Dexie from 'dexie'; //Indexeddb access to store user token for saving push interaction
import VizSensor from 'react-visibility-sensor'; //Get updated tracking data if user brings component into view after having navigated away from page
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {store, useGlobalState} from 'state-pool';
import {globalNotificationTokenArray} from './GlobalNotificationTokenArray.js';

const logAnalyticsCurrentPage = () => {
	analytics.setCurrentScreen(window.location.pathname); // sets `screen_name` parameter
	analytics.logEvent('screen_view'); // log event with `screen_name` parameter attached
};


// store.setState("globalNotificationToken", {token: null});

const renderLoader = () => <p>Loading</p>

function MyProfile () {
	logAnalyticsCurrentPage();

	// const [globalNotificationToken, setGlobalNotificationToken, updateGlobalNotificationToken] = useGlobalState("globalNotificationToken");
	
	var authToken = localStorage.getItem('AuthToken');
	// console.log("Auth Token from storage is " + authToken);
	// axios.create({
 //  		baseURL: "https://us-central1-habitprompt-aedfc.cloudfunctions.net/api"
	// });
	// axios.defaults.baseURL = "https://us-central1-habitprompt-aedfc.cloudfunctions.net/api";
	axios.defaults.headers.common = { Authorization: `${authToken}` };
	// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


	const [userData, setUserdata] = useState(null);
	const [trackingData, setTrackingData] = useState(null);

	const [pushNotificationToken, setPushNotificationToken] = useState(null);
	const [hackingMultipleTokenByCasusingViewToRefresh,setHackingMultipleTokenByCasusingViewToRefresh] = useState(null);
	
	let userNameOnPage = null;
	var dailyWaterPercentage = null;
	var dailyMovementPercentage = null;
	var dailyMindfulnessPercentage = null;
	var waterUpdatedOn = null;
	var sundayPercentage = null;
	var mondayPercentage = null;
	var tuesdayPercentage = null;
	var wednesdayPercentage = null;
	var thursdayPercentage = null;
	var fridayPercentage = null;
	var saturdayPercentage = null;



	//NEED TO FIGURE OUT HISTORY FOR react router <Redirect> to get the below commented code to work
	// SO WE CAN ASK THE USER TO LOGIN AGAIN WHEN THEIR TOKEN EXPIRES!!!! -> BETTER TO CHANCE FIREBASE AUTH TOKEN TO NOT EXPIRE
	// BECAUSE RIGHT NOW USER WON"T BE ABLE TO SAVE THEIR PUSH INTERACTION TO DB IF THEIR AUTH HAS EXPIRED.
	//Or push them to the login modal if they try to directly access the page 
	//console.log('This is: ' + this);
	//console.log('history is: ' + this.props.history);
	//authMiddleWare(this.props.history);


	//Register service worker that handles push when app is not in view for user
	// Handle unsuported browser messaging here
	if ('serviceWorker' in navigator) {
  		navigator.serviceWorker.register('firebase-messaging-sw.js', {scope: '/'})
  		.then((reg) => {
	        // Registration worked.
	        // console.log('Registration succeeded. Scope is ' + reg.scope);
      	})
	      	.catch((error) => {
	        // Registration failed.
	        // console.log('Registration failed with ' + error.message);
      	});
	} else {
  		window.location.assign('/unsupported');
	}
	
	// Store authToken in browser indexedDB so message service firebase-messagingsw.js can access it to post user daily tracking
	// when user interacts with the yes/ no in the notification
	var indexedDb = new Dexie('FormDatabase');
	useEffect (() =>{
		//create the indexdb store to store user authtoken
		indexedDb.version(1).stores({ formData: 'id,value' });
	},);

	//maybe try IDB-Keyval instead as having issues upadting index db on token update
	useEffect(() => {
		indexedDb.transaction('rw',indexedDb.formData , async () => {
			//Check if there's alerady an authtoken in store
			const existingAuthToken = await indexedDb.formData.get('authToken');
			if(!existingAuthToken){
				await indexedDb.formData.add({ id: 'authToken', value: authToken });
				//console.log('In index DB transaction add userEffect' + indexedDb.formData);
			}else{
				await indexedDb.formData.put({ id: 'authToken', value: authToken });
				//console.log('In index DB transaction put userEffect' + indexedDb.formData);
			}
			
		}).then(() => {
    		// use newly stored auth token to get user data
    		//console.log('*** User data was retrieved **** ' + authToken);
    		axios
				.get('/api/user')
				.then((response) => {
					setUserdata(response.data.userCredentials);
				})
				.catch((error) => {
	       			// errors: error.response.data;
	        		console.log(error.response);
	     		 });

			axios
				.get('/api/member-tracking')
				.then((response) => {
					setTrackingData(response.data);
				})
				.catch((error) => {
	        		console.log(error.response);
	     		});


		}).catch((err) => {
			//console.log(err.stack || err);
			console.log(err);
		});
		return () => indexedDb.close();
	},[]);	



//~~~~~~~ END FIREBASE AUTH TEST CODE ~~~~~~~~// 

	//Using refresh token to retrieve new id token to keep user logged in as id token expires every hr
	// There is a bug in the onIdTokenChange code where it is only called if the user refreshes the page: https://github.com/firebase/firebase-js-sdk/issues/2985
	// Find another way to trigger the call every hour so a new authtoken is obtained to save data from push notification interactions

	useEffect(() => {
			firebaseAuth.onIdTokenChanged(function(user) {

		//console.log("~~~ On Id Token Change Observer was triggered ~~~ ");

		if (user) {
		// User is signed in or token was refreshed.
			// console.log('User refresh token is: ')
			// console.log(user.refreshToken);
			// console.log('User id token [For API calls ] is: ' + user.getIdToken);
			
			user.getIdToken(true)
			.then((userRefreshedIdToken) => { 
				//console.log('User id token is: ' + userRefreshedIdToken)
				//Adding new authtoken after it refreshes to local storage to be used by axios called to server
				localStorage.setItem('AuthToken', `Bearer `+ userRefreshedIdToken);
				
			})
			.then(()=>{
				authToken = localStorage.getItem('AuthToken');
				axios.defaults.headers.common = { Authorization: `${authToken}` };//Why do this? -could use userRefreshedIdToken instead for index db
				//IMPORTANT!!!: Need to update authtoken in indexDB - how does axion pick up the new auth token but not indexDb??
				//ABOVE IS IMPORTANT !!!!! 
				//Adding new authtoken after it refreshes to indexDb so firebase messaging service worker can use it to post user's 
				//choise of Yes / No on a messsage	

			})
			.then(()=>{
				//IF USER IS LOGGED OUT UNCOMMENT THE BELOW AND FIX THE 'DB IS OPEN ISSUE'
				//REMEMBER THAT THE KEY FROM INDEXDE IS USED TO SAVE / SEND DATA TO THE DATABASE 
				// AND NOT FOR LOGIN/ LOGOUT STATES
				indexedDb.open().catch (function (err) {
				    console.error('Failed to open db: ' + (err.stack || err));
				});

				// console.log('Index Db is: ' + indexedDb);
				// console.log('Index DB formData is: ', indexedDb.formData);

			})
			.then(()=>{

				indexedDb.transaction('rw',indexedDb.formData , async () => {
		
					await indexedDb.formData.put({ id: 'authToken', value: authToken });

					//Check if there's alerady an authtoken in store
					const TempAuthToken = await indexedDb.formData.get('authToken');

					// console.log('IndexDB Form data value after authToken updates' + TempAuthToken.value);
					indexedDb.close();

				})
				.catch((err) => {
					// console.log(err.stack || err);
					console.log(err);
				});

			})
			.catch((err) => {
			//console.log(err.stack || err);
			console.log(err);
		});
		}else{		    
			console.log('Cannot get current user - user not signed in');
		    console.log(user);
		}
	});
	}, []);



	





	useEffect(()=>{
			// Give permission to app to display push notification
	// !!!REVIEW AND JUST USE THE .getToken() as per the doc - Might fix multiple token generation issue at initialisation.
	//When user grants premission in browser multiple token is generated
	//If permission is being given for the first time 
		//use the first token only 
	//If browser is refreshed, store the new token (Do we even needs this as this will still cause 2 notifications if 1st token is active)

		messaging.requestPermission()
		.then(() => {
			console.log('Push notificatin permission granted');


			
			return messaging.getToken();
		})
		.then((token) =>{
	//FIGURE OUT HOW TO FIX THE SAVING OF THE MULTIPLE TOKENS THAT GET GENERATED AFTER PERMISSION IS FIRST GRANTED	
			//declare global array
			//if array length is greater than 2 update set push notification token
			//Reason - when user first gives permission 3 tokens are generated with 2 or more valid causing 
			//user to recieve multiple push notifications 
			globalNotificationTokenArray.push(token);
			console.log('~~~!!!GlobalNotificationTokenArray length OUTSIDE IF ' + globalNotificationTokenArray.length);


			
			//Change to > 3
			if ((globalNotificationTokenArray.length === 1) || (globalNotificationTokenArray.length > 3)){
			// if ((globalNotificationTokenArray.length >= 2)){
				setPushNotificationToken(token);
							console.log('GlobalNotificationTokenArray length is ' + globalNotificationTokenArray.length);
							console.log('Token is: ' + token);

			}else if(globalNotificationTokenArray.length === 3){
				setHackingMultipleTokenByCasusingViewToRefresh(token);
			}

		})
		.catch((err) => {
			console.log('Error occured ' + err );		 
		});

	},[]);




	if(userData){

		//~~test Code ~~~ 
			//console.log("Current user is " + firebaseAuth.currentUser);
		//~~~ End test code ~~~~~

		//user data used on page
		userNameOnPage = userData.firstName;
		
		//Updating push notification array in db
		let newUserData = [];

		
	



		if(pushNotificationToken){
			//update token in user object in db by calling update user api
			//Timezone here would change only if user is now in a new country as well as
			//giving notification for the first time from a new devie or having refrehsed permision in a browser
			//rare but possible condition
			

			if(userData.pushNotificationTokens){
				let arraySize = userData.pushNotificationTokens.length;
				if(userData.pushNotificationTokens[arraySize-1] !== pushNotificationToken){
					userData.pushNotificationTokens.push(pushNotificationToken);
				
					axios
					    .post('/api/user', userData)
					    .then((response) => {
					     
					    })
					    .catch((error) => {
					    // errors: error.response.data;
					    	console.log(error.response);
					    });  

					axios
						.post('/api/update-push-token',userData)
						.then((response) =>{

						})
						.catch((error) => {
							console.log(error.response);
						});
					
				}

			}else{
				//user signing up for the first time implies that push notification array would not 
				//exist yet in user details in db
				newUserData = {
					pushNotificationTokens: [pushNotificationToken],
					timezone: userData.timezone
				}

				axios
				    .post('/api/user', newUserData)
				    .then((response) => {			     
				    })
				    .catch((error) => {
				    	console.log(error.response);
				    }); 

				axios
					.post('/api/update-push-token',newUserData)
					.then((response) =>{

					})
					.catch((error) => {
						console.log(error.response);
					});  		
			}



 
		}
	}

	if(trackingData){
			dailyWaterPercentage = trackingData[0].water.trackedWater +'%';
			dailyMovementPercentage = trackingData[0].movement.trackedMovement +'%';
			dailyMindfulnessPercentage = trackingData[0].mindfulness.trackedMindfulness +'%';
			sundayPercentage = trackingData[0].weekly.sun + '%';
			mondayPercentage = trackingData[0].weekly.mon + '%';
			tuesdayPercentage = trackingData[0].weekly.tue + '%';
			wednesdayPercentage = trackingData[0].weekly.wed + '%';
			thursdayPercentage = trackingData[0].weekly.thu + '%';
			fridayPercentage = trackingData[0].weekly.fri + '%';
			saturdayPercentage = trackingData[0].weekly.sat + '%';
		
	}


	var  componentInViewPort = function(isVisible){
		//console.log('Element is now %s', isVisible ? 'visible' : 'hidden');
		//call tracking again if element is in viewport as uer navigates back to page 
	};


	//Code to handle Push notification when app is in viewport
	const handlePushYesAction = (messageBody) => {
		// console.log('Yes was pressed from Toast notification');
		// console.log('The message body is: ' + messageBody);

		//Can use dalilyPercentage as when page loads it would be the lates value from db. 
		//Need to ensure a "page reload" is triggered after the calculation to update the value in db 
		//for this functionality to work


		

		// NEED TO GET THE LATEST TRACKING DATA FROM DB INSTEAD OF USING WHAT'S IN THE UI AS
		// MEMBER MIGHT HAVE INTERACTED WITH NOTIFICATION OUT OF VIEWPORT HENCE IN VIEWPORT DATA 
		// WILL NOT NOT BE THE MOST RECENT ONE.  

		//parse message body to know if it's water, movement or mindfullness being updated. 
		
		axios
			.get('/api/member-tracking')
			.then((response) => {
				setTrackingData(response.data);
			})
			.then(()=>{
				
				var currentTrackedWater = trackingData[0].water.trackedWater;
				var currentTrackedMindfulness = trackingData[0].mindfulness.trackedMindfulness;
				var currentTrackedMovement = trackingData[0].movement.trackedMovement;
				var currentWeeklyTracking = trackingData[0].weekly;

				// console.log("Current tracked water is " + currentTrackedWater);
				// console.log("Current tracked mindfulness data is " + currentTrackedMindfulness);
				// console.log("Current tracked movement data is " + currentTrackedMovement);
	
				//Can use water's last updated on date as last updated on date as afer any notification
				//interaction all 3 of water, movement, mindfullness are update to the current date and time
				var lastUpdatedOnDateTime = trackingData[0].water.updatedOn;
				var lastUpdatedOnDate = lastUpdatedOnDateTime.split(',')[0]; 
				var lastUpdatedOnTime = lastUpdatedOnDateTime.split(',')[1];

				var currentDateTime =  new Date().toLocaleString('en-GB');
				var currentDate = currentDateTime.split(',')[0];
				var currentTime = currentDateTime.split(',')[1];

				var dateParts = lastUpdatedOnDate.split("/");
			
				//new Date takes format mm/dd/yyyy hence need to split date into parts first as server returns dd/mm/yyyy
				// new Date takes new Date(year, monthIndex, day) hence ned to split date into parts first as server returns dd/yy/mm
				console.log("Date parts debugging");
				console.log("Current date time: " + currentDateTime);
				console.log("Last updated date " + lastUpdatedOnDate);
				console.log("Last updated date parts: ");
				console.log("Date part [2] i.e. yyyy " + dateParts[2]);				
				console.log("Date part [1] i.e. mm index " + dateParts[1]);
				console.log("Date part [0] i.e. dd " + dateParts[0]);
				console.log("New date object constructed by date part is");
				console.log(JSON.stringify(new Date(+dateParts[2],dateParts[1]-1, +dateParts[0])));
				console.log("What the new date object should actually be: ")
				console.log(JSON.stringify(new Date(dateParts[2],dateParts[1]-1,dateParts[0])));
				console.log("International date time format ")
				console.log(JSON.stringify (Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long' }).format(new Date(+dateParts[2],dateParts[1]-1, +dateParts[0]))));



				var lastDayUpdatedOnDay = Intl.DateTimeFormat('en-GB', {weekday:'long'}).format(new Date(+dateParts[2],dateParts[1]-1, +dateParts[0]));
				console.log("lastDayUpdatedOnDay is " + lastDayUpdatedOnDay);
				
				var newWaterValue = 0;
				var newMovementValue = 0;
				var newMindfulnessValue = 0;


				if (lastUpdatedOnDate !== currentDate ) {
					//console.log("It's a new day. Store previous day data and start incrementing todays percentage");

					var newTodayValueForWeeklyGraph = 0;

					//New values depending on messsage since we're starting a new day
					if(messageBody.includes("water")) {
						newWaterValue = 20;
						newMovementValue = 0;
						newMindfulnessValue = 0;
						//console.log("We'll be incrementing water");
					}else if (messageBody.includes("walk") || messageBody.includes("stretches") || messageBody.includes("move")){									
						newWaterValue = 0;
						newMovementValue = 25;
						newMindfulnessValue = 0;
						//console.log("We'll be incrementing movement");
					}else if (messageBody.includes("mindfully") || messageBody.includes("meditating")){
						newWaterValue = 0;
						newMovementValue= 0;
						newMindfulnessValue = 50;
						//console.log("We'll be incrementing mindfulness");
					}else{
						//console.log("Error parsing the received message");
					}

					newTodayValueForWeeklyGraph = Math.round((newWaterValue + newMovementValue + newMindfulnessValue)/3);
				

					//SET UP CODE IF USER HAS NOT LOGGED IN FOR A COUPLE OF DAYS 
					//new Date takes format mm/dd/yyyy hence need to split date into parts first as server returns dd/mm/yyyy
					var currentDateParts = currentDate.split("/");
					var currentDateDateObject = new Date(+currentDateParts[2],currentDateParts[1]-1, +currentDateParts[0]);

					var lastUpdatedOnDateDateObject = new Date(+dateParts[2],dateParts[1]-1, +dateParts[0]);

					// console.log('last updated date is ' + lastUpdatedOnDateDateObject );
					// console.log('Current date is ' + currentDateDateObject);

					var differenceInTimeBtwDates = currentDateDateObject.getTime() - lastUpdatedOnDateDateObject.getTime();
					var differenceInDaysBtwDates = Math.round(differenceInTimeBtwDates/(1000*3600*24));
					// console.log("Last updated date minus current date =  " + differenceInDaysBtwDates + "days between the 2 dates" );

					var todaysDayName = Intl.DateTimeFormat('en-GB', {weekday:'long'}).format(new Date(+currentDateParts[2],currentDateParts[1]-1, +currentDateParts[0]));
					// console.log("*** Today is: " + todaysDayName);
				
					//THIS IS GOOD CODE TO BE KEPT. 
					if(differenceInDaysBtwDates > 1){
						//If the user did not log in for a whilte and they log in on Sunday it'll trigger
						// the code further down that says if yesterday was Saturday. 
						if(todaysDayName === "Sunday"){
							lastDayUpdatedOnDay = "Saturday";
							// console.log("Updating lastDayUpdatedOnDay to Saturday as today is Sunday");
						}else if (todaysDayName === "Saturday"){
							if(differenceInDaysBtwDates === 2){
								//user logged in on thursday
								//set friday to zero
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = newTodayValueForWeeklyGraph;

							}else if(differenceInDaysBtwDates === 3){
								//user logged in on Wed 
								//set Fri and Thu to zero 
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = newTodayValueForWeeklyGraph;
							}else if(differenceInDaysBtwDates === 4){
								//user logged in on Tue
								//set Fri, Thu, Wed to zero
								currentWeeklyTracking.wed = 0;
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = newTodayValueForWeeklyGraph;								
							}else if(differenceInDaysBtwDates === 5){
								//user logged in ont Mon
								//set Fri, Thur, Wed, Tue to zero
								currentWeeklyTracking.tue = 0;
								currentWeeklyTracking.wed = 0;
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = newTodayValueForWeeklyGraph;	
							}else if(differenceInDaysBtwDates === 6){
								//user logged in on Sun
								// set Fri, Thur, Wed, Tue, Mon to Zero	
								currentWeeklyTracking.mon = 0;
								currentWeeklyTracking.tue = 0;
								currentWeeklyTracking.wed = 0;
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = newTodayValueForWeeklyGraph;	
							}else{
								//It's been more than last Sun that user logged in
								// Set Fri, Thurs, Wed, Tue, Mon, Sun to zero
								currentWeeklyTracking.sun = 0;
								currentWeeklyTracking.mon = 0;
								currentWeeklyTracking.tue = 0;
								currentWeeklyTracking.wed = 0;
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = newTodayValueForWeeklyGraph;	
							}
						}else if (todaysDayName === "Friday"){
							if(differenceInDaysBtwDates === 2){
								//user logged in on wed 
								//set Thu to zero - Sat should be zoro too
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.sat = 0;

							}else if(differenceInDaysBtwDates === 3) {
								//user logged in on tuesday
								//set wed, thur, and Sat to zero
								currentWeeklyTracking.wed = 0; 
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.sat = 0;

							}else if(differenceInDaysBtwDates === 4){
								//user logged in on Monday
								//set tue, wed, thur, and sat to zero
								currentWeeklyTracking.tue = 0; 
								currentWeeklyTracking.wed = 0; 
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.sat = 0;

							}else if(differenceInDaysBtwDates === 5){
								//user logged in on Sunday
								// set mon, tue, wed, thur, sat to zero 
								currentWeeklyTracking.mon = 0; 
								currentWeeklyTracking.tue = 0; 
								currentWeeklyTracking.wed = 0; 
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.sat = 0;


							}else{
								//It's been more than last sun that user logged in
								//set sun,mon,tue,wed,thur, and sat to zero.
								currentWeeklyTracking.sun = 0; 								
								currentWeeklyTracking.mon = 0; 
								currentWeeklyTracking.tue = 0; 
								currentWeeklyTracking.wed = 0; 
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.sat = 0;
							}

						}else if(todaysDayName === "Thursday"){
							if(differenceInDaysBtwDates === 2){
								//user logged in on Tue
								//Set wed, fri, sat to zero
								currentWeeklyTracking.wed = 0;
								currentWeeklyTracking.thu = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = 0;
							}else if(differenceInDaysBtwDates === 3){
								//user logged in on Mon
								//set tue, wed, fri, sat to zero
								currentWeeklyTracking.tue = 0;
								currentWeeklyTracking.wed = 0;
								currentWeeklyTracking.thu = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = 0;

							}else if(differenceInDaysBtwDates === 4){
								//user logged in on sun
								//set mon, tue, wed, fri, sat to zero
								currentWeeklyTracking.mon = 0;
								currentWeeklyTracking.tue = 0;
								currentWeeklyTracking.wed = 0;
								currentWeeklyTracking.thu = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = 0;

							}else {
								//It's been more than last sun that user logged in
								//set sun,mon,tue,wed, fri, sat to zero
								currentWeeklyTracking.sun = 0;
								currentWeeklyTracking.mon = 0;
								currentWeeklyTracking.tue = 0;
								currentWeeklyTracking.wed = 0;
								currentWeeklyTracking.thu = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = 0;
							} 
						}else if(todaysDayName === "Wednesday"){
							if(differenceInDaysBtwDates === 2){
								//user logged in on mon
								//set Tue,Thu,Fri,Sat to zero
								currentWeeklyTracking.tue = 0;
								currentWeeklyTracking.wed = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = 0;

							}else if(differenceInDaysBtwDates === 3){
								//user logged in on Sunday
								//set mon,tue,thu,fri,sat to zero
								currentWeeklyTracking.mon = 0;
								currentWeeklyTracking.tue = 0;
								currentWeeklyTracking.wed = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = 0;
							}else {
								//It's been more than last sun that user logged in
								//set sun,mon,tue,thur,fri to zero
								currentWeeklyTracking.sun = 0
								currentWeeklyTracking.mon = 0;
								currentWeeklyTracking.tue = 0;
								currentWeeklyTracking.wed = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = 0;
							}
						}else if(todaysDayName === "Tuesday"){
							if(differenceInDaysBtwDates === 2){
								//user logged in on Sunday
								//set Mon,wed,thu,fri,sat to zero
								currentWeeklyTracking.mon = 0;
								currentWeeklyTracking.tue = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.wed = 0;
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = 0;
							}else{
								//It's been more than last sun that user logged in
								//set sun,mon,tue,thur,fri to zero
								currentWeeklyTracking.sun = 0;
								currentWeeklyTracking.mon = 0;
								currentWeeklyTracking.tue = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.wed = 0;
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = 0;
							}
						}else {
							//it's Monday and difference in days is > 1 
							//set sun, tue, wed, thu, fri, sat to zero as diff in days is > 1 
								currentWeeklyTracking.sun = 0;
								currentWeeklyTracking.mon = newTodayValueForWeeklyGraph;
								currentWeeklyTracking.tue = 0;
								currentWeeklyTracking.wed = 0;
								currentWeeklyTracking.thu = 0;
								currentWeeklyTracking.fri = 0;
								currentWeeklyTracking.sat = 0;

						}

						//Axio post the right data to server here
						var newDailyTrackingData = {
							dailyWaterTracking: newWaterValue,
							dailyMovementTracking: newMovementValue,
							dailyMindfulnessTracking: newMindfulnessValue,
							weeklyTracking: currentWeeklyTracking,
							updatedOn: currentDateTime
						}

						axios
							.post('/api/member-tracking/',newDailyTrackingData)
							.then((response) =>{

							})
							.catch((error) => {
								console.log(error.response);
							});  
					} 

					//<<<<< NOTE!!!!!!-> MIGHT NEED TO RELOOK AT THOSE OUTER IF STATEMENTS >>>>>>>>
						//Note that the differenceInDaysBtwDates handdles the fact that Month will be changing when calculating days. 	

					else if(lastDayUpdatedOnDay === "Saturday"){
						// console.log("Yesterday was saturday");
						var satTotalPercentage = Math.round((currentTrackedWater + currentTrackedMindfulness + currentTrackedMovement) / 3);

						//NEED to SET THE BELOW IN THE YEAR OBJECT!!! 
						var weekTotalPercentage = Math.round((trackingData[0].weekly.sun + trackingData[0].weekly.mon + trackingData[0].weekly.tue + trackingData[0].weekly.wed + trackingData[0].weekly.thu + trackingData[0].weekly.fri + satTotalPercentage)/7);
						// console.log("Weekly tracking is " + weekTotalPercentage);

						var newDailyTrackingData = {
							dailyWaterTracking: newWaterValue,
							dailyMovementTracking: newMovementValue,
							dailyMindfulnessTracking: newMindfulnessValue,
							weeklyTracking:{ 
								sun: newTodayValueForWeeklyGraph,
								mon: 0,
								tue: 0,
								wed: 0,
								thu: 0,
								fri: 0,
								sat: 0,
							},
							updatedOn: currentDateTime
						}

						axios
							.post('/api/member-tracking/',newDailyTrackingData)
							.then((response) =>{

							})
							.catch((error) => {
								console.log(error.response);
							});  

					} else {
						var yesterdaysTotalPercentage = Math.round((currentTrackedWater + currentTrackedMindfulness + currentTrackedMovement) / 3);

						// console.log("yep, yesterday was not saturday but it is a new day and the difference b/w the dayes is equal to 1");
						//note cannot use for loop on trackingData[0].weekly as sun to sat aren't stroed in order in db
						var todaysDateParts = currentDate.split("/");					
						//new Date takes format mm/dd/yyyy hence need to split date into parts first as server returns dd/mm/yyyy
						var todaysDay = Intl.DateTimeFormat('en-GB', {weekday:'long'}).format(new Date(+todaysDateParts[2],todaysDateParts[1]-1, +todaysDateParts[0]));
						// console.log("Today's day is " + todaysDay);
					
						//using those as keys to update the weeklyTracking data object
						var days =['sun','mon','tue','wed','thu','fri','sat'];
						
						var weeklyTracking = trackingData[0].weekly;
						var todaysDayAbreviated = null;
						var yesterdaysDayAbreviated = null;

						Object.keys(weeklyTracking).forEach(key => {
							if(todaysDay.toLowerCase().includes(key)){
								weeklyTracking[key] = newTodayValueForWeeklyGraph;
								todaysDayAbreviated = key;
							}
						});

						//Finding what day yesterday was to update weekly tracking data object
						for(let i = 0; i < days.length ; i++) {
							if(days[i]===todaysDayAbreviated){
								yesterdaysDayAbreviated = days[i -1];
							}
						}

						weeklyTracking[yesterdaysDayAbreviated] = yesterdaysTotalPercentage;
						// console.log("weekly tracking value yesterday is " + weeklyTracking[yesterdaysDayAbreviated])
						// console.log('Yesterday was ' + yesterdaysDayAbreviated);
						
						// //console for debugging:
						// Object.keys(weeklyTracking).forEach(key => {
						//   console.log(key, weeklyTracking[key]);
						// });

						var newDailyTrackingData = {
							dailyWaterTracking: newWaterValue,
							dailyMovementTracking: newMovementValue,
							dailyMindfulnessTracking: newMindfulnessValue,
							weeklyTracking: weeklyTracking,
							updatedOn: currentDateTime
						}

						axios
							.post('/api/member-tracking/',newDailyTrackingData)
							.then((response) =>{

							})
							.catch((error) => {
								console.log(error.response);
							});  

					}
					// [X]Note: Add a week database object on account creation i.e. in users.js and set all days to zero. Object should be added on user account creation
					// [/]Note: also add a year object to database (in users.js) with each month name and number of week (google if all years always match to 52 weeks and all months always same number of days except feb this should be true)
						//week database object has Sun to Sat
							//If the lastUpdatedOn was a Saturday
								// [X]Calculate Saturday's total percentage
								// [X] Calculate week's total percentage by including above sat value where sat value = (mindfulness + movement + water for the day )/3
									//Store last week's total percentage value in Yearly tracking object for yearly goal graphing
								//[X]Calculate sunday's total percentage (should only include the 1 new value)
								//[X]set new sunday percentage in weeek object in db
								//[X]clear all other days for the week object i.e. set to zero 
								//[/ - yearly not done yet]set daily for today, weekly and the last week for yearly tracked data in db
								//[X]update UI tracking data object in UI to value from db
								//[X]Refresh UI

								 //WHAT IF USER DOES NOT INTERACT WITH NOTIFICATION FOR SEVERAL DAYS/ WEEK? 
								 	//Need to somehow check the ACTUAL current date and the last date data was updated in DB and fill the gap with zeros to generate the week and yearly graphs
								 	//Handle week first, which is easier. "Worse case" is from a Sunday to a Saturday since if it's a sunday other days of the week is already set to zero
								 	//Then handle year by checking last updated on for year and fill in the gap
								 		// Note Might be easier if the week and year graphs are displayed in UI for this

							//else i.e yesterday was not saturday
								//[X]Calculate yesterdays total percentage 
								// [X]set yersterday's total percentage in week object in db
								// [X]calculate today's total percentage so far and set in week object-> Should only be the 1 value divided by 11 as we're starting a new day
								// [X]Set today's total percentage in week object 
								// [X]set daily tracked data for today in db 
								// [X]update ui trackingData data object in UI to value from db
								// [X]refresh ui

				} else if (lastUpdatedOnDate === currentDate && currentTime !== lastUpdatedOnTime ) {
					// console.log("Yes last updated date equals to current date and it's a different time on the same day. Hence update percentage");
					//[X]Set new trackingData values
					//[X]update trackingdata object in UI to value from db
					//[X]refresh UI
					if(messageBody.includes("water")) {
						newWaterValue = currentTrackedWater + 20;
						newMovementValue = currentTrackedMovement;
						newMindfulnessValue = currentTrackedMindfulness;
						// console.log("We'll be incrementing water");
					}else if (messageBody.includes("walk") || messageBody.includes("stretches") || messageBody.includes("move")){									
						newWaterValue = currentTrackedWater;
						newMovementValue = currentTrackedMovement + 25;
						newMindfulnessValue = currentTrackedMindfulness;
						// console.log("We'll be incrementing movement");
					}else if (messageBody.includes("mindfully") || messageBody.includes("meditating")){
						newWaterValue = currentTrackedWater;
						newMovementValue= currentTrackedMovement;
						newMindfulnessValue = currentTrackedMindfulness + 50;
						// console.log("We'll be incrementing mindfulness");
					}else{
						// console.log("Error parsing the received message");
					}

					var todaysDateParts = currentDate.split("/");	

					var todaysDayAbreviated = null;
						//new Date takes format mm/dd/yyyy hence need to split date into parts first as server returns dd/mm/yyyy
					var todaysDay = Intl.DateTimeFormat('en-GB', {weekday:'long'}).format(new Date(+todaysDateParts[2],todaysDateParts[1]-1, +todaysDateParts[0]));

					
					var tempWeeklyTracking = currentWeeklyTracking;
					Object.keys(tempWeeklyTracking).forEach(key => {
						if(todaysDay.toLowerCase().includes(key)){
							todaysDayAbreviated = key;
						}
					});

	
					tempWeeklyTracking[todaysDayAbreviated] = Math.round((newWaterValue + newMovementValue + newMindfulnessValue) / 3);

					var newDailyTrackingData = {
						dailyWaterTracking: newWaterValue,
						dailyMovementTracking: newMovementValue,
						dailyMindfulnessTracking: newMindfulnessValue,
						weeklyTracking: tempWeeklyTracking,
						updatedOn: currentDateTime
					}

					axios
						.post('/api/member-tracking/',newDailyTrackingData)
						.then((response) =>{

						})
						.catch((error) => {
							console.log(error.response);
						});  

				} else {
					console.log("Date and time are exactly as per the last notification - this is a bug");
				}

			})
			.then(()=>{
				//Refresh UI after new tracking value are saved after notification interaction
				axios
					.get('/api/member-tracking')
					.then((response) => {
						setTrackingData(response.data);
					})
					.catch((error) => {
		        		console.log(error.response);
		     		});

			})
			.catch((error) => {
        		console.log(error.response);
     		});
	


		//[X]What if user logs in several days later and hasn't interacted with notification for coupl of days
			//[X]Store all days from the latest login date to the last login date as 0 for the weekly graph data
		//[X]Above is working out of the box? - it might not work you skip interaction with notificaiton on a sunday for all days to be reset to zero 
		// [X]need to check difference b/w dates is no greater than 1 day first if not make sure all missed days is set to zero first. 
		// [X]i.e. need an else if after checking if the day yesterday was saturday that says, else if the differnce b/w todays date and the last recorded date is x days
		// [X]those missing days should be set to zero and the new day value incremented. 
	

	};

	const handlePushNoAction = (messageBody) => {
		console.log("No was pressed from Toast notification");
		console.log('The message body is: ' + messageBody);

	};
		

	let notificationMessageTitleWhenAppInViewport = null;
	let notificationMessageBodyWhenAppInViewport = null;
	const Msg = ({closeToast}) => (
	  <div>
	  	<div className='notification_in_viewport_message_text'>
		    {notificationMessageTitleWhenAppInViewport}
		</div>
		<div className='notification_in_viewport_message_text'>
		    {notificationMessageBodyWhenAppInViewport}
		</div> 
		<div className="notification_in_viewport_message_button_container">
			<div className='notification_in_viewport_message_yes_button_div'> 
				<button  className='notification_in_viewport_message_yes_button' onClick={() => handlePushYesAction(notificationMessageBodyWhenAppInViewport)}>Yes</button>
			</div>
			<div className='notification_in_viewport_message_no_button_div'>
				<button className='notification_in_viewport_message_no_button' onClick={() => handlePushNoAction(notificationMessageBodyWhenAppInViewport)}>No</button>
			</div>
		</div>
	  </div>
	)

	//Handling push when app is in viewport
	messaging.onMessage((payload) => {
//NOTE: Change later to show notification badge (instead of push) 
// For now. Loggin to console.
		console.log('Push is in console log as app is in view')
		console.log('Message payload when page is open ', payload);

		
		// Below was copied from background message code - clean up and cater for a pop up to display when app is in foreground
		const action_button_json = payload.data.actions;

		const parsedActionButtonJSON = JSON.parse(action_button_json);

		console.log('Actions are ' + parsedActionButtonJSON);

		const title = payload.data.title;
		const body = payload.data.body;
		// const options = {
		// 	body: payload.data.body,
		// 	icon: "/pushicon.png",
		// 	image: "/pushimage.jpg",
		// 	click_action: "https://habitprompt.com",
		// 	actions: parsedActionButtonJSON.actions		
		// };

		// console.log('Message foreground title is: ' + title);
		// console.log('Message foreground opitons are ' + parsedActionButtonJSON.actions);
		//Above parsing works i.e. can get the data and print to console. All need to do now is put the data in an alert. 


		notificationMessageTitleWhenAppInViewport = title;
		notificationMessageBodyWhenAppInViewport = body;

		toast(<Msg />)		

	});


//NOTE: Redo the CSS for the daily trackers on this page instead of using the homepage css to make them smalller
//Some style sheet making fonts small on the page - figure out as last thing before release

	const sunday = {
	    height: sundayPercentage
	};
	const monday = {
	    height: mondayPercentage
	};
	const tuesday = {
		    height: tuesdayPercentage
	};
	const wednesday = {
		    height: wednesdayPercentage
	};
	const thursday = {
		    height: thursdayPercentage
	};
	const friday = {
		    height: fridayPercentage
	};
	const saturday = {
		    height: saturdayPercentage
	};

	return(
		<React.Fragment>

			<div className='container'>
				
				<ToastContainer
					position="top-center"
					hideProgressBar={true}
					autoClose={false}
					toastStyle={{ backgroundColor: "#BEBEBE", cursor: "auto" }}
				/>

				<h1>Welcome back {userNameOnPage}!</h1>	
				<h3 style={{marginBottom:"20px"}}> Good habits lead to a better lifestyle </h3>

				<Suspense fallback={renderLoader()}>
					<section>
						<div className='daily_performance_div'>
							<h3>Your habit performance for today</h3>
							<div className='cards__container'>
				        		<div className='cards__wrapper'>
				          			<ul className='cards__items'>	
				          					<VizSensor onChange={componentInViewPort} intervalDelay={100}>	          				
						            			<CardItem
						              				src='images/water_drinking_icon.png'
						              				text='Water drinking'
						                      		percent={dailyWaterPercentage}
						            			/>
						            		</VizSensor>
					            			<CardItem
					              				src='images/movement_icon.png'
					              				text='Movement'
					                      		percent={dailyMovementPercentage}
					            			/>
					            			<CardItem
					              				src='images/yoga_icon.png'
					              				text='Mindfulness practice'
					                      		percent={dailyMindfulnessPercentage}
					            			/>
									</ul>
								 </div>
							</div>
						</div>
					</section>
					<section>
						<div className='weekly_performance_div'>
							<h3>Your habit tracking for the current week</h3>
							<div className='weekly_chart_container'>
									<div className='weekly_performance_chart'>

										<div>

											<div className="day_goal">Day goal achieved!</div>
										</div>

										<div className="progress_bars">

											<div className='sunday_tracker'>
												<div className="yellow_line"></div>
												<div className='day_tracker'>
									              <span className='day_progress_bar' style={sunday}></span>
									            </div>
									            <div className="white_line"></div>	
									            <div className="day_name">Sun</div>
								            </div>	
											<div className='monday_tracker'>
												<div className='day_tracker'>
									              <span className='day_progress_bar' style={monday}></span>
									            </div>
									            <div className="day_name">Mon</div>
								            </div>	
											<div className='tuesday_tracker'>
												<div className='day_tracker'>
									              <span className='day_progress_bar' style={tuesday}></span>
									            </div>
									            <div className="day_name"> Tue</div>
								            </div>	
											<div className='wednesday_tracker'>
												<div className='day_tracker'>
									              <span className='day_progress_bar' style={wednesday}></span>
									            </div>
									            <div className="day_name">Wed</div>
								            </div>	
											<div className='thursday_tracker'>
												<div className='day_tracker'>
									              <span className='day_progress_bar' style={thursday}></span>
									            </div>
									            <div className="day_name">Thu</div>
								            </div>	
											<div className='friday_tracker'>
												<div className='day_tracker'>
									              <span className='day_progress_bar' style={friday}></span>
									            </div>
									            <div className="day_name">Fri</div>
								            </div>	
											<div className='saturday_tracker'>
												<div className='day_tracker'>
									              <span className='day_progress_bar' style={saturday}></span>
									            </div>
									            <div className="day_name">Sat</div>
								            </div>	
								        </div>
								</div>
							</div>
						</div>
					</section>
				</Suspense>
			</div>
		</React.Fragment>
	);
}

export default MyProfile;