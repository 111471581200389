import React from 'react';
import '../App.css';
import './HeroSection.css';
import { Link } from 'react-scroll';
import { analytics } from '../Firebase.js';

var logClickEvent = function (){
	 analytics.logEvent('click_sign_up_button_homepage_hero');
}

const logAnalyticsCurrentPage = () => {
	analytics.setCurrentScreen(window.location.pathname); // sets `screen_name` parameter
	analytics.logEvent('screen_view'); // log event with `screen_name` parameter attached
};


function HeroSection () {
	logAnalyticsCurrentPage();
	return(
		<div className='hero-container'>
			<h1>Your digital companion for building healthy habits</h1>		
			<Link
				to='join-form' 
				className='btn-mobile'
				spy={true}
				smooth={true} 
				duration={800} 
			>
 				<div className='hero-btn' onClick={logClickEvent}>
                	Sign Up
            	</div>
        </Link>
		</div>
	)
}

export default HeroSection;



