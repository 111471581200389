import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-scroll';
import { Modal } from 'react-responsive-modal';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { firebaseAuth } from '../Firebase.js';
import { HashLink } from "react-router-hash-link";

import axios from 'axios';
import './Navbar.css';
import 'react-responsive-modal/styles.css';
import NavContext from './NavbarGlogbalContext';


function Navbar(props) {

  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);

  //Login in Modal
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const { register, handleSubmit, errors, formState } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [beingSubmitted, setBeingSubmitted] = useState(false);
  const [notSubmitted, setNotSubmitted] = useState(true);
  const [redirecData, setRedirectData] = useState(null);
  const [loginError, setLoginError] = useState(false);


  const [readingBlog, setReadingBlog] = useState(false);

  const login_modal = {
    modal: {
      background:'#393E46',
      minWidth: '320px',
      color: 'white',
      borderRadius: '10px'
    }
  }
  
  //Send user back to homepage on logout
  const history = useHistory();

  //Login logout handling
  const [loggedIn, setLoggedIn] = useState(false);

  const { testSetLoggedIn, setTestSetLoggedIn } = useContext(NavContext);

  
  useEffect(() => {
    const logInState = localStorage.getItem('loggedIn') || false;
    setLoggedIn(logInState);
    console.log("Logged in state is " + localStorage.getItem('loggedIn') );

  },[])


  useEffect(() => {
    setLoggedIn(testSetLoggedIn);
  },[testSetLoggedIn])

  
//This was causing the logged in navbar to show. Commented it out and it fixed the issue. Looks like this is redundant.
  // useEffect(()=>{
  //   localStorage.setItem("loggedIn", loggedIn);
  // },[loggedIn]);

  const logoutHandler = (event) => {


    firebaseAuth.signOut().then(() => {
    // Sign-out successful.
      console.log('Executing logout');
      localStorage.removeItem('AuthToken'); 
      localStorage.removeItem('loggedIn');
      setLoggedIn(false);
      setSubmitted(false);
      setNotSubmitted(true);
      setLoginError(false);
      history.push('/');
    }).catch((error) => {
      console.log("Sign out error: " + error);
    });

  };

  
  const routeHome = (event) => {
    closeMobileMenu();
    setReadingBlog(false);
    history.push('/');
  };
  const routeToProfile = (event) => {
    closeMobileMenu();
    setReadingBlog(false);
    history.push('/myprofile');
  };

  const routeToFeedbackPage = (event) => {
    closeMobileMenu();
    setReadingBlog(false);
    history.push('/feedback');
  };  

  const routeToBlog = (event) => {
    closeMobileMenu();
    setReadingBlog(true);
    history.push('/blog', {onBlog: true});
  };   

  const navigateAwayFromBlog = (event) => {
    closeMobileMenu();
    setReadingBlog(false);
    history.push('/')
  }; 




  const submitFormData = (data) => {

    setBeingSubmitted(true);
    setNotSubmitted(false);
    setLoginError(false);

    const userData = {
      email: data.email,
      password: data.password
    };

   
    // axios
    //   .post('/login', userData)
    //   .then((response) => {
    //     localStorage.setItem('AuthToken', `Bearer ${response.data.token}`);
    //     setRedirectData(response);
    //     setBeingSubmitted(false);
    //     setLoggedIn(true);
    //     localStorage.setItem('loggedIn', true); 
    //     setSubmitted(true);
    //     setOpen(false);
    //   })
    //   .catch((error) => {
    //    // errors: error.response.data;
    //     setBeingSubmitted(false);
    //     console.log(error.response);
    //     setNotSubmitted(true);
    //     setLoginError(true);
    //   }); 



    firebaseAuth.signInWithEmailAndPassword(data.email, data.password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        user.getIdToken().then((idToken) => {
          localStorage.setItem('AuthToken', `Bearer `+ idToken);
        })
        .then(()=>{
          // setRedirectData(response);
          setBeingSubmitted(false);
          setLoggedIn(true);
          localStorage.setItem('loggedIn', true); 
          setSubmitted(true);
          setOpen(false);
        })
        .catch((err) => {
          console.log(err.stack || err);
          console.log(err);
        });

      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setBeingSubmitted(false);
       // console.log(error.response);
        setNotSubmitted(true);
        setLoginError(true);
    });


  }

  return (
   <React.Fragment>

      <nav className='navbar'>

        <div className='navbar-container'>
          <Link to='root' href='/' className='navbar-logo' onClick={routeHome} spy={true} smooth={true} duration={800}>
            <img alt='habitprompt' src='/Logo.svg' className='logo-icon' />
            <div className='logo-text'>habitprompt</div>
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li>
                <Link to='/blog' href ='/blog' className='nav-links' onClick={routeToBlog} smooth={true} duration={800}>
                 Blog
                </Link>
            </li>




            <li>
                { loggedIn && <Link to='/feedback' className='nav-links' onClick={routeToProfile} smooth={true} duration={800}>
                 Profile
                  </Link>
                }
            </li>
            <li>
               { !loggedIn && <HashLink to='/#cards' className='nav-links' onClick={closeMobileMenu} smooth={true} duration={800}>
                Stats
                  </HashLink>
                }                

{/*                { !loggedIn && readingBlog && <Link to='cards' className='nav-links' onClick={navigateAwayFromBlog} smooth={true} duration={800}>
                Stats
                  </Link>
                } */}


               { loggedIn && <Link to='/feedback' className='nav-links' onClick={routeToFeedbackPage} smooth={true} duration={800}>
                Contact Us
                  </Link>
                }
            </li>
            <li>
               { !loggedIn && <HashLink to='/#join-form' className='nav-links' onClick={closeMobileMenu} smooth={true} duration={800}>
                Sign Up
                 </HashLink>
               }
{/*               { loggedIn && <Link to='join-form' className='nav-links' onClick={closeMobileMenu} smooth={true} duration={800}>
                Support
                 </Link>
               }*/}
            </li>
            <li>
              { !loggedIn && <button className='nav-links login-button' onClick={openModal}>
                Log In 
                </button>
              }
             { loggedIn && <button className='nav-links login-button' onClick={logoutHandler}>
                 Log Out 
                 </button>    
             }
            </li>
          </ul>
        </div>
      </nav>

        <Modal open={open} onClose={closeModal} styles={login_modal}>
          <div className='login-form'>
            <form onSubmit={handleSubmit(submitFormData)}>
              <div className='login-input-group'>
                <label htmlFor='email'>Email:</label>
                <input 
                  type='email'
                  name='email'
                  id='email'
                  className='email-input'
                  ref={register({ required: "Email is required" })}
                  style={{borderColor: errors.email && "red"}}
                />
                {errors.email && <p className='error-message'>{errors.email.message}</p>}
              </div>
              <div className='login-input-group'>
                <label htmlFor='password'>Password:</label>
                <input 
                  type='password'
                  name='password'
                  id='password'
                  className='password-input'
                  ref={register({ required: "Password is required" })} 
                  style={{borderColor: errors.password && "red" }}
                />
                {errors.password && <p className='error-message'>{errors.password.message}</p>} 
              </div>
              <div className='login-input-group'>
                {loginError && <p className='error-message' style={{marginLeft:15, marginBottom:10}}>Wrong credentials, please try again</p>}
                <button
                  className='submit-button'
                  style={{backgroundColor: submitted ? "#54be59" : '#FD4974' }}
                  disabled={formState.isSubmitting || submitted}
                  >
                    {notSubmitted && <span>Submit</span>}
                    {beingSubmitted && <i className="fa fa-spinner fa-spin"></i>}
                    {submitted && <span>Redirecting...</span>}
                    {submitted && <Redirect
                              push to={{
                                  pathname: '/myprofile',
                                  // state: {userId: redirecData.id}
                              }}
                            />}
                    
                  </button>        
              </div>  
            </form>
          </div>
        </Modal>

   </React.Fragment>
  );
}

export default Navbar;